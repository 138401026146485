import React from "react"
import styles from "./TodoForm.module.css"

const TodoForm = props => {

  const {
    taskInput,
    projectInput,
    priorityInput,
    onHandleAddItem,
    onHandleChangeTask,
    onHandleChangeProject,
    onHandleChangePriority
  } = props

  return (
    <form className={styles['todo-form']} onSubmit={onHandleAddItem}>
      <div className={`component-form-row ${styles['todo-form-row']}`}>
        <div className="component-form-control-box">
          <label className="component-form-label" htmlFor="todo-task">Task</label>
          <input className="component-form-control" id="todo-task" type="text" maxLength="64" onChange={onHandleChangeTask} value={taskInput} placeholder="Text your task..." />
        </div>
        <div className="component-form-control-box">
          <label className="component-form-label" htmlFor="todo-project">Project</label>
          <input className="component-form-control" id="todo-project" list="todo-projet-datalist" type="text" maxLength="32" onChange={onHandleChangeProject} value={projectInput} placeholder="My project" />
          <datalist id="todo-projet-datalist">
            <option value="Cleaning" />
            <option value="Programming" />
            <option value="Cooking" />
            <option value="Entertainment" />
            <option value="Shopping" />
            <option value="Other" />
          </datalist>
        </div>
        <div className="component-form-control-box">
          <label className="component-form-label" htmlFor="todo-priority">Priority</label>
          <div className="component-form-control-box component-form-control-box--select">
            <select className="component-form-control component-form-control--select" id="todo-priority" onChange={onHandleChangePriority} value={priorityInput}>
              <option value="0">Low</option>
              <option value="1">Normal</option>
              <option value="2">High</option>
            </select>
            <svg className="component-form-control-select-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24">
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg>
          </div>
        </div>
        <div className="component-form-control-box">
          <button className={`component-btn component-btn--success ${styles['todo-btn']}`} type="submit">Add New Task</button>
        </div>
      </div>
    </form >
  )
}

export default TodoForm