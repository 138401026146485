import React from "react"
import TodoItem from "./TodoItem"
import TodoItemHeader from "./TodoItemHeader"
import styles from "./TodoList.module.css"

const TodoList = props => {

  const {
    todoList,
    priorityDic,
    filter,
    todoStatusMessage,
    onHandleComplete,
    onHandleRemove
  } = props

  const filteredTodoList = todoList.filter(filteredTodoItem => {
    return (
      (filter === '0') ||
      (filter === '1' && filteredTodoItem.completed) ||
      (filter === '2' && !filteredTodoItem.completed)
    )
  });

  let todoListContent = (
    <div className="component-info-box component-info-box--warning">
      <div className="component-info-box__body">
        <div className="component-info-box__desc">{todoStatusMessage}</div>
      </div>
    </div>
  )

  if (filteredTodoList.length > 0) {
    todoListContent = filteredTodoList.map((item) => {

      const todoItem = {
        id: item.id,
        completed: item.completed,
        task: item.task,
        project: item.project,
        priority: window.parseInt(item.priority, 10)
      }

      return <TodoItem
        key={item.id}
        todoItem={todoItem}
        priorityDic={priorityDic}
        onHandleComplete={onHandleComplete}
        onHandleRemove={onHandleRemove}
      />
    })
  }

  return (
    <div className={styles['todo-list']}>
      <TodoItemHeader />
      {todoListContent}
    </div>
  )
}

export default TodoList