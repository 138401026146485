import React from 'react'
import stylesCommon from './TodoItemCommon.module.css'
import styles from './TodoItem.module.css'

const TodoItem = props => {

  const { todoItem, priorityDic, onHandleComplete, onHandleRemove } = props
  const { id, completed, task, project, priority } = todoItem
  const priorityCls = styles['todo-priority-box--' + priorityDic[priority]]
  const priorityIconCls = styles['todo-priority-icon--' + priorityDic[priority]]

  return (
    <div className={`${stylesCommon['todo-item']}${completed ? ' ' + styles['todo-item--completed'] : ''}`}>
      <div className={styles['todo-item-box']}>
        <div className={styles['todo-item-box-label']}>Completed:</div>
        <div className={styles['todo-item-box-content']}>
          <div className={styles['todo-completed']}>
            <div className="component-toggle-switch-box">
              <label className="component-toggle-switch" htmlFor={`todo-complete-${id}`}>
                <input className="component-toggle-switch__form-control" id={`todo-complete-${id}`} type="checkbox" value="1" onChange={(event) => { onHandleComplete(event, id) }} defaultChecked={completed} />
                <span className="component-toggle-switch__bar">
                  <span className="component-toggle-switch__on">
                    <svg className="component-toggle-switch__on-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
                  </span>
                  <span className="component-toggle-switch__off">
                    <svg className="component-toggle-switch__off-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles['todo-task']}>
        <div className={styles['todo-item-box']}>
          <div className={styles['todo-item-box-label']}>Task:</div>
          <div className={styles['todo-item-box-content']}>
            {task || 'Unnamed task'}</div>
        </div>
      </div>

      <div className={styles['todo-project']}>
        <div className={styles['todo-item-box']}>
          <div className={styles['todo-item-box-label']}>Project:</div>
          <div className={styles['todo-item-box-content']}>
            {project || 'Unnamed project'}
          </div>
        </div>
      </div>

      <div className={styles['todo-priority']}>
        <div className={styles['todo-item-box']}>
          <div className={styles['todo-item-box-label']}>Priority:</div>
          <div className={styles['todo-item-box-content']}>
            <span className={`${styles['todo-priority-box']} ${priorityCls}`}>
              {priorityDic[priority]}
              <span className={`${styles['todo-priority-icon']} ${priorityIconCls}`}></span>
            </span>
          </div>
        </div>
      </div>

      <div className={styles['todo-action']}>
        <button className={`${styles['todo-btn']} component-btn component-btn--error`} type="button" onClick={() => { onHandleRemove(id) }}>
          <svg className={`${styles['todo-btn-icon']} component-btn__icon`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M6 21h12V7H6v14zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /></svg>
        </button>
      </div>
    </div>
  )
}

export default TodoItem