import React from "react"
import styles from "./TodoFilter.module.css"

const TodoFilter = (props) => {

    const { filter, onHandleFilter } = props;

    const cls = styles['todo-filter-btn'] + ' component-btn component-btn--default';
    const clsIsActive = styles['is-active'];

    return (
        <div className={styles['todo-filter']}>
            <ul className={styles['todo-filter-list']}>
                <li className={styles['todo-filter-item']}>
                    <button onClick={onHandleFilter} data-filter-id="0" className={`${cls}${filter === '0' ? ' ' + clsIsActive : ''}`} type="button">All</button>
                </li>
                <li className={styles['todo-filter-item']}>
                    <button onClick={onHandleFilter} data-filter-id="1" className={`${cls}${filter === '1' ? ' ' + clsIsActive : ''}`}>Completed</button>
                </li>
                <li className={styles['todo-filter-item']}>
                    <button onClick={onHandleFilter} data-filter-id="2" className={`${cls}${filter === '2' ? ' ' + clsIsActive : ''}`} type="button">Uncompleted</button>
                </li>
            </ul>
        </div>
    )
}

export default TodoFilter