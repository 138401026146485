import React, { useState, useEffect } from 'react'
import TodoForm from './TodoForm'
import TodoFilter from './TodoFilter'
import TodoList from './TodoList'
import styles from "./Todo.module.css"
import { db } from '../firebase-config'
import { collection, doc, getDocs, setDoc, updateDoc, deleteDoc, query, orderBy } from 'firebase/firestore'
import { uid } from 'uid'

const Todo = () => {

  const [todoList, setTodoList] = useState([])
  const [todoStatusMessage, setTodoStatusMessage] = useState('Loading...')
  const [taskInput, setTaskInput] = useState('')
  const [projectInput, setProjectInput] = useState('')
  const [priorityInput, setPriorityInput] = useState(1)
  const [uniqueId, setUniqueId] = useState(uid())
  const [filter, setFilter] = useState('0') // '0': All | '1': Completed | '2': Uncompleted

  const priorityDic = ['low', 'normal', 'high']
  const todoListCollectionRef = collection(db, 'todoList')

  const handleAddItem = (event) => {

    event.preventDefault();

    setUniqueId(uid())

    const newItem = {
      id: uniqueId,
      timestamp: + new Date(),
      task: taskInput,
      project: projectInput,
      priority: priorityInput
    }

    createTodoItemToDb(newItem);
    setTodoList((prevState) => [newItem, ...prevState])
    resetForm();
  }

  // Get Data from DB
  const getTodoListFromDb = async () => {
    const q = query(todoListCollectionRef, orderBy('timestamp', 'desc'))
    const data = await getDocs(q)

    setTodoList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    if (todoList.length === 0) {
      setTodoStatusMessage('There is not any item in the list yet.')
    }
  }

  // Call Get Data from DB when DOM is ready
  useEffect(() => {
    getTodoListFromDb()
  }, [])

  // Write Data to DB
  const createTodoItemToDb = async (item) => {
    const todoDoc = doc(db, 'todoList', uniqueId);
    await setDoc(todoDoc, item)
  }

  // Update Data in DB
  const updateTodoItemInDb = async (id, status) => {
    const todoDoc = doc(db, 'todoList', id)
    await updateDoc(todoDoc, { completed: status });
  }

  const handleComplete = (event, id) => {

    setTodoList(() => {
      return todoList.map(item => {
        if (item.id === id) {
          item.completed = event.target.checked
        }
        return item;
      })
    })
    updateTodoItemInDb(id, event.target.checked)
  }

  // Delete Data from DB
  const removeTodoItemFromDb = async (id) => {
    const todoDoc = doc(db, 'todoList', id)
    await deleteDoc(todoDoc);
  }

  const handleRemove = (id) => {
    setTodoList(() => todoList.filter(item => item.id !== id))
    removeTodoItemFromDb(id)
  }

  const handleChangeTask = (event) => {
    setTaskInput(event.target.value.slice(0, event.target.maxlength))
  }

  const handleChangeProject = (event) => {
    setProjectInput(event.target.value.slice(0, event.target.maxlength))
  }

  const handleChangePriority = (event) => {
    let val = window.parseInt(event.target.value);
    val = (/0|1|2/.test(val)) ? val : 1
    setPriorityInput(val)
  }

  const resetForm = () => {
    setTaskInput('')
    setProjectInput('')
    setPriorityInput(1)
  }

  const handleFilter = (event) => {
    const filterId = event.target.dataset.filterId;
    setFilter(filterId);
  }

  return (
    <div className={styles.todo}>

      <h1>Todo List</h1>

      <TodoForm
        taskInput={taskInput}
        projectInput={projectInput}
        priorityInput={priorityInput}
        onHandleAddItem={handleAddItem}
        onHandleChangeTask={handleChangeTask}
        onHandleChangeProject={handleChangeProject}
        onHandleChangePriority={handleChangePriority}
      />

      <TodoFilter filter={filter} onHandleFilter={handleFilter} />

      <TodoList
        todoList={todoList}
        priorityDic={priorityDic}
        todoStatusMessage={todoStatusMessage}
        filter={filter}
        onHandleComplete={handleComplete}
        onHandleRemove={handleRemove}
      />
    </div>
  )
}

export default Todo
