import React from 'react'
import styles from './TodoItemCommon.module.css'

const TodoItemHeader = () => {

    return (
        <div className={`${styles['todo-item']} ${styles['todo-item--header']}`}>
            <div className="component-text-center">Status</div>
            <div className="component-text-start">Task</div>
            <div className="component-text-start">Project</div>
            <div className="component-text-end">Priority</div>
            <div className="component-text-center">Action</div>
        </div>
    )
}

export default TodoItemHeader